.cover_adaptable {
  -webkit-mask-image: linear-gradient(to bottom, black 0%, rgb(0 0 0 / 5%) 93%, rgb(0 0 0 / 0%) 100%);
  mask-image: linear-gradient(to bottom, black 0%, rgb(0 0 0 / 5%) 93%, rgb(0 0 0 / 0%) 100%);
  opacity: 0.9;
  position: absolute;
  z-index: -1;
}

.cover_tv {
  -webkit-mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
    mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
    opacity: 0.9;
    position: absolute;
    z-index: -1;
}

.cover_tv_v2 {
//  -webkit-mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
//  mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
  opacity: 0.9;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
}

// for new login page 23
.cover_adaptable2 {
  // -webkit-mask-image: linear-gradient(to bottom, black 0%, rgb(0 0 0 / 5%) 93%, rgb(0 0 0 / 0%) 100%);
  //  mask-image: linear-gradient(to bottom, black 0%, rgb(0 0 0 / 5%) 93%, rgb(0 0 0 / 0%) 100%);
  opacity: 0.9;
  position: absolute;
  z-index: -1;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .cover {
    -webkit-mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
    mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
    opacity: 0.9;
    position: absolute;
    z-index: -1;
  }

  .cover_v2 {
  //  -webkit-mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
  //  mask-image: linear-gradient(to left, black 0%, rgb(0 0 0 / 5%) 53%, rgb(0 0 0 / 0%) 70%);
    opacity: 0.9;
    position: absolute;
    z-index: -1;
  }


}
