.custom-form-input {
  color: #C0C2C8;
}

.custom-form-input .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red !important;
}

.custom-form-input .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red !important;
  opacity: 0.8 !important;
}

.custom-form-input .mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
  caret-color: red !important;
}

.custom-form-input .mat-form-field.mat-form-field-invalid.mat-focused .mat-form-field-label {
  color: red !important;
}

.custom-form-input input.mat-input-element {
  font-size: 13px;
  color: #C0C2C8;
}

.custom-form-input .mat-form-field {
  color: #C0C2C8 !important;
  font-size: 13px;
  width: 100%;
}

.custom-form-input .mat-form-field-empty.mat-form-field-label {
  color: #C0C2C8 !important;
}

.custom-form-input .mat-form-field-appearance-outline .mat-form-field-outline {
  background-color: #040A26 !important;
  color: #040A26 !important;
  border-radius: 5px;
}

.custom-form-input .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #040A26 !important;
}

.custom-form-input .mat-input-element {
  caret-color: #C0C2C8 !important;
}

.custom-form-input .mat-form-field-label {
  color: #C0C2C8 !important;
}

.custom-form-input .mat-form-field.mat-focused .mat-form-field-label {
  color: #C0C2C8 !important;
}

.custom-form-input .mat-form-field-flex:hover .mat-form-field-outline {
  opacity: 0;
  transition: none !important;
}

.mat-checkbox-frame {
  border-color: #C0C2C8
}

.main-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-title {
  color: white;
  font-size: 25px;
  font-weight: 500;
}

.reminder-checkbox {
  color: #C0C2C8;
  font-size: 13px;
}

.password-recovery-label {
  color: #C0C2C8;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.underline-warning-text {
  color: var(--ion-color-warning);
  background: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
  text-align: center
}

.white-text {
  font-size: 13px;
  color: white;
}

.form-container {
  background: none;
}

.form-logo {
  height: 35.83px;
  width: 150px;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .form-logo {
    height: auto;
    width: 200px;
  }

  .main-form-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: normal;
  }

  .form-container {
    background-color: #00255A;
  }

}
