/*! @name videojs-seek-buttons @version 2.2.1 @license Apache-2.0 */
.video-js .vjs-seek-button {
  font-family: 'VideoJS';
  cursor: pointer;
  font-weight: 400;
  font-style: normal
}

.video-js .vjs-seek-button.skip-back::before, .video-js.vjs-v6 .vjs-seek-button.skip-back .vjs-icon-placeholder::before, .video-js.vjs-v7 .vjs-seek-button.skip-back .vjs-icon-placeholder::before {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  content: '\f116'
}

.video-js .vjs-seek-button.skip-forward::before {
  transform: rotateY(180deg) rotate(-45deg);
  -ms-transform: rotateY(180deg) rotate(-45deg);
  -webkit-transform: rotateY(180deg) rotate(-45deg);
  content: '\f116'
}

.video-js.vjs-v6 .vjs-seek-button.skip-back::before, .video-js.vjs-v6 .vjs-seek-button.skip-forward::before, .video-js.vjs-v7 .vjs-seek-button.skip-back::before, .video-js.vjs-v7 .vjs-seek-button.skip-forward::before {
  content: none
}

.video-js.vjs-v6 .vjs-seek-button.skip-forward .vjs-icon-placeholder::before, .video-js.vjs-v7 .vjs-seek-button.skip-forward .vjs-icon-placeholder::before {
  transform: scale(-1, 1) rotate(-45deg);
  -ms-transform: scale(-1, 1) rotate(-45deg);
  -webkit-transform: scale(-1, 1) rotate(-45deg);
  content: '\f116'
}
