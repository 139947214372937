.eventCardsVertical{
    color: var(--ion-color-primary-contrast);
    padding: 1em 0 0 1em;
}

.eventCardsVertical h3{
    font-weight: bold;
    padding-left: 0.5em;
    font: normal normal normal 28px/37px Roboto;
}

.eventCardsVertical ion-row {
    position: relative;
    align-items: center;
}

.eventCardsVertical ion-segment{
    display: flex;
    flex-direction: row;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.segment-tv {
    width: 100%;
}

.eventCardsVertical ion-segment::-webkit-scrollbar{
    display: none;
}

.eventCardsVertical__Item{
    width: 228px;
    height: 327px;
    position: relative;
    display: flex;
    cursor: pointer;
    margin-right: 1em;
    border-radius: 5px;
    background-color: var(--ion-color-primary-tint);
}

.tv-control:focus {
    border: 4px solid white;
    outline: none;
}

.tv-control-none:focus {
    border: none !important;
    outline: none;
}

.eventCardsVertical__Item::before{
    background: transparent linear-gradient(180deg, #16213E00 0%, #16213EA8 48%, #16213E 100%) 0% 0% no-repeat padding-box;
    right: 0;
    height: 50%;
    bottom: 0;
    content: "";
    width:100%;
    position: absolute;
}

.eventCardsVertical ion-img{
    width: 228px; //15vw;
    height: 99%;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
}

.eventCardsVertical .icoPlayInternal {
    position: absolute;
    max-width: 45px;
    max-height: 45px;
    bottom: 4em;
    left: 0.5em;
    z-index: 1;
    visibility: hidden;
}

.eventCardsVertical__Item:hover > .icoPlayInternal{
    visibility: visible;
}

.eventCardsVertical__Item:hover > .eventCardsVertical__Info{
    z-index: 10;
}

.eventCardsVertical__Item:hover .infoTitle{
    font-weight: bolder;
}

.eventCardsVertical__Item:hover::after{
    background: transparent linear-gradient(180deg, #16213E00 0%, #16213EA8 48%, #16213E 100%) 0% 0% no-repeat padding-box;
    right: 0;
    height: 100%;
    bottom: 0;
    content: "";
    width:100%;
    position: absolute;
    border-bottom: 5px solid var(--ion-color-warning);
    border-radius: 5px;
}

.eventCardsVertical__Info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.5em;
    bottom: 0.75em;
    left: 1em;
    width: 210px;
}

.eventCardsVertical__Info .infoTitle{
    width: 100%;
    font-size: 1.5em;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.carrousel__arrow {
    position: absolute;
    color: var(--ion-color-primary-contrast);
    cursor: pointer;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff88 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #1522414B;
    border: 0.5px solid #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    width: 40px;
    height: 40px;
}

.carrousel__arrow ion-img {
    width: 20px;
    opacity: 1;
    margin: 0.25em 0 0 0 ;
    padding: 0;
}

.arrowLeft {
    left: -1em;
    transform: rotate(180deg);
}

.arrowRight {
    right: 0em;
}

.multi_entitlement {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 1.7em;
    background-color: #3264DC;
    border-radius: 5px 5px 0 0;
}

.multi_entitlement p {
    position: absolute;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    visibility: visible;
    padding: 0 5px 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 1200px) {

    .eventCardsVertical{
        width: 100%;
    }

    .segment-tv {
        width: 99%;
    }

    .eventCardsVertical__Item{
        width: 200px;
        height: 300px;
    }

    .eventCardsVertical ion-img{
        width: 200px;
    }

    .eventCardsVertical .icoPlayInternal{
        bottom: 2em;
        left: -0.5em;
    }

    .eventCardsVertical__Info{
        width: 190px;
        left: 0.25em;
        bottom: 0.5em;

    }

    .eventCardsVertical .infoTitle {
        font-size: 1em;
        line-height: 1em;
    }

    .carrousel__arrow {
        display: none;
    }
}

@media (max-width: 991px){

    .eventCardsVertical{
        padding: 1em 0 0 0.5em;
        width: 100%;
    }

    .eventCardsVertical h3{
        font-size: 1em;
        line-height: 0;
        padding-left: 0.75em;
    }

    .eventCardsVertical ion-grid{
        max-width: 100vw;
    }

    .eventCardsVertical ion-row{
        width: 98vw;
    }

    .segment-tv-vertical {
        width: 90%;
    }

    .eventCardsVertical__Item{
        width: 104px;
        height: 149px;
    }

    .eventCardsVertical ion-img{
        width: 104px;
    }

    .eventCardsVertical__Info{
        width: 100px;
        // height: 28px;
        left: 0.25em;
    }

    .eventCardsVertical__Info > ion-label {
        font-size: 0.75em;
    }

    .eventCardsVertical__Info .infoTitle{
        line-height: 1.15em;
        font-size: 12px;
    }

    .multi_entitlement {
        height: 1.2em;
    }

    .multi_entitlement p {
        font-size: 10px;
    }
}