/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "../scss/components/forms.scss";
@import "../scss/components/cover.scss";
@import "../scss/components/toast.scss";
@import "../scss/components/eventCardsHorizontal.scss";
@import "../scss/components/eventCardsVertical.scss";
@import "../scss/components/refresh.scss";

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';


.ios .top-bar {
  padding-top: var(--ion-safe-area-top) !important;
  margin-top: 15px;
}

.ios .logo{
  padding-top: var(--ion-safe-area-top);
}

/*ModalController-INIT*/
ion-modal {
  --width: fit-content;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  //.component { margin: 30px; }
  .text-center { margin: 20px; }
}
.modal-wrapper {
  margin: 0 40px 0 40px!important;
}
/*ModalController*/


.swiper-pagination-bullet {
  background: white;
}

.custom-alert-class {
  --background: #ffffff!important;
  --color:#FF0000!important;
}

.modal-entitlement-web{
  --height: 100%;
  --width: 100%;
}


.backdropBackground {
  background-color: #FF0000;
}

.my-custom-modal-css .modal-wrapper {

  height: 18rem;
  width: 25rem;
  position: absolute;

  background: linear-gradient(180deg, #121D3A 0%, #1D3367 100%);
}

.my-custom-modal-css-bottom .modal-wrapper {

  height: 20rem;
  width: 25rem;
  position: absolute;
  background: linear-gradient(180deg, #121D3A 0%, #1D3367 100%);
}

/// tv modal user concurrent

.limit-custom-device-tv {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 600px;
}

@media (max-width: 991px){
  .limit-custom-device-tv{
    --max-width: 80% !important;
    --max-height: 90% !important;
  }
}

@media (max-width: 319px) {
  .limit-custom-device-tv{
    --max-width: 100% !important;
  }
}

////

.limit-custom-device  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 600px;
}

@media (max-width: 991px){
  .limit-custom-device{
    --max-width: 80% !important;
    // --max-height: 90%;
  }
}

@media (max-width: 319px) {
  .limit-custom-device{
    --max-width: 100% !important;
  }
}

.modal-custom-device  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 700px;
}


@media (max-width: 991px){
  .modal-custom-device {
    --max-width: 95% !important;
  }
}

@media (max-width: 319px) {
  .modal-custom-device {
    --max-width: 90% !important;
  }
}

/// webGL modal

.modal-webgl  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 700px;
}


@media (max-width: 991px){
  .modal-webgl {
    --max-width: 95% !important;
  }
}

@media (max-width: 319px) {
  .modal-webgl {
    --max-width: 90% !important;
  }
}


.modal-delete-custom-device  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 700px;
}


@media (max-width: 991px){
  .modal-delete-custom-device {
    --max-width: 95% !important;
  }
}

@media (max-width: 319px) {
  .modal-delete-custom-device {
    --max-width: 90% !important;
  }
}

///// model user master device tv


.limit-custom-device-play-tv {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 600px;
}

@media (max-width: 991px){
  .limit-custom-device-play-tv {
    --max-width: 95% !important;
    --max-height: 80% !important;
  }
}

@media (max-width: 319px) {
  .limit-custom-device-play-tv {
    --max-width: 100% !important;
  }
}


///// multi-entitlement modal tv


.multi-entitlement-modal-tv {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 700px;
}

@media (max-width: 991px){
  .multi-entitlement-modal-tv {
    --max-width: 95% !important;
    --max-height: 80% !important;
  }
}

@media (max-width: 319px) {
  .multi-entitlement-modal-tv {
    --max-width: 100% !important;
  }
}

////

.limit-custom-device-play  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 600px;
}

@media (max-width: 991px){
  .limit-custom-device-play {
    --max-width: 95% !important;
  }
}

@media (max-width: 319px) {
  .limit-custom-device-play {
    --max-width: 100% !important;
  }
}

// multi-entitlement modal
.multi-entitlement-modal  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 700px;
}

@media (max-width: 991px){
  .multi-entitlement-modal {
    --max-width: 95% !important;
    --max-height: 95% !important;
  }
}

@media (max-width: 319px) {
  .multi-entitlement-modal {
    --max-width: 100% !important;
    --max-height: 100% !important;
  }
}


// limit-custom-device-noMaster

.limit-custom-device-noMaster  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --width: 600px;
}

.limit-custom-terms-Conditions  {
  --border-radius: 24px;
  --border-color: #FFBE00;
  --border-style: solid;
  --border-width: 2px;
  --background: #040A26;
  --max-width: 700px;
}


@media (max-width: 991px){
  .limit-custom-device-noMaster {
    --max-width: 95% !important;
  }
}

@media (max-width: 319px) {
  .limit-custom-device-noMaster {
    --max-width: 100% !important;
  }
}

.toast-custom  {
  --height: 4em;
  --width: 100%;
  --border-radius: 0px;
  top: -70%;
  --max-width: 100% !important;
}

@media (max-width: 992px) {
  .toast-custom {
    top: calc(-100vh + 4em);
    margin-top: env(safe-area-inset-top);
  }
}
// .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
//   opacity: 0 !important;
//   background-color: #FFBE00 !important;
// }

// .mat-radio-button.mat-accent .mat-radio-inner-circle {
//   background-color: #FFBE00 !important;
//   z-index: 3;
// }

// .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
//   border-color: #FFBE00 !important;
//   z-index: 3;
// }



@media (max-width: 991px) {
  .my-custom-modal-css-bottom .modal-wrapper {

   // height: 37%;
    width: 100%;
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:75px;
    background: linear-gradient(180deg, #121D3A 0%, #1D3367 100%);
  }

  .my-custom-modal-css .modal-wrapper {
    margin-top: -10%;
    height: 18rem;
    width: 22rem;
    position: absolute;
    display: block;
    background: linear-gradient(180deg, #121D3A 0%, #1D3367 100%);
  }
  /*ModalController-INIT*/
  ion-modal {
    --max-width: 300px;
  }
  /*ModalController*/
}
