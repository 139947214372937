// Custom Theming for Angular Material
// @use '~@angular/material' as mat;
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$blue-palette);
$app-accent: mat.define-palette(mat.$amber-palette);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);



@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');


// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Roboto", sans-serif;
  --ion-font-family-helvetica: Roboto, sans-serif;
  --ion-font-family-fjalla: 'Fjalla One', sans-serif;

  /** primary **/
  --ion-color-primary: #071029;
  --ion-color-primary-rgb: 7,16,41;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #060e24;
  --ion-color-primary-tint: #20283e;

  /** secondary **/
  --ion-color-secondary: #00c8ff;
  --ion-color-secondary-rgb: 0, 200, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #00b0e0;
  --ion-color-secondary-tint: #1aceff;

  /** tertiary **/
  --ion-color-tertiary: #152241;
  --ion-color-tertiary-rgb: 21,34,65;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #121e39;
  --ion-color-tertiary-tint: #2c3854;

  /** success **/
  --ion-color-success: #62BA46;
  --ion-color-success-rgb: 98, 186, 70;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #56a43e;
  --ion-color-success-tint: #72c159;

  /** warning **/
  --ion-color-warning: #ffbe00;
  --ion-color-warning-rgb: 255, 190, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0a700;
  --ion-color-warning-tint: #ffc51a;

  /** danger **/
  --ion-color-danger: #f01e46;
  --ion-color-danger-rgb: 240, 30, 70;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d31a3e;
  --ion-color-danger-tint: #f23559;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  body {
    --ion-background-color: #152241;
    font-family: var(--ion-font-family);
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, sans-serif; }
