ion-refresher ion-refresher-content {
    --color: #FFF !important;

    .refresher-pulling-text,
    .refresher-refreshing-text {
        color: #fff !important;
        font-size: 15px;        
        font-family: var(--ion-font-family-fjalla);
        margin-bottom: 10px;
    }

    .refresher-refreshing-icon,
    .refresher-pulling-icon {
        color: #fff !important;
        font-size: 30px;
    }
}