.eventCardsHorizontal{
    color: var(--ion-color-primary-contrast);
    padding: 1em 0 0 1em;
}

.tvFormat{
    margin-top: -5vh;
}

.eventCardsHorizontal h3{
    font-weight: bold;
    padding-left: 0.5em;
    font: normal normal medium 28px/37px Roboto;
}

.eventCardsHorizontal ion-row {
    position: relative;
    align-items: center;
}

.eventCardsHorizontal ion-segment{
    display: flex;
    flex-direction: row;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.segment-tv-horizontal {
    width: 100%;
}

.eventCardsHorizontal ion-segment::-webkit-scrollbar{
    display: none;
}

.eventCardsHorizontal__Item{
    width: 574px;
    height: 327px;
    position: relative;
    display: flex;
    cursor: pointer;
    margin-right: 1em;
    background-color: var(--ion-color-primary-tint);
}

.followdItemCardsHorizontal__Item{
    position: relative;
    display: flex;
    cursor: pointer;
    margin-right: 1em;
}

.tv-control:focus {
    border: 4px solid white;
    outline: none;
}

.tv-control-none:focus {
    border: none !important;
    outline: none;
}

.eventCardsHorizontal__Item::before{
    background: transparent linear-gradient(180deg, #16213E00 0%, #16213EA8 48%, #16213E 100%) 0% 0% no-repeat padding-box;
    right: 0;
    height: 50%;
    bottom: 0;
    content: "";
    width:100%;
    position: absolute;
}

.eventCardsHorizontal__Item > ion-img{
    width: 574px;
    height: 99%;
    object-fit: cover;
}

.eventCardsHorizontal .icoPlayInternal {
    position: absolute;
    max-width: 45px;
    max-height: 45px;
    bottom: 4em;
    left: 0.5em;
    z-index: 1;
    visibility: hidden;
}

.eventCardsHorizontal .tagLive{
    position: absolute;
    background-color: #F01E46;
    width: 95px;
    height: 25;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 30px;
    right: 1em;
    top: 1.5em;
    padding: 0 0.5em;
}

.eventCardsHorizontal__Item:hover > .icoPlayInternal{
    visibility: visible;
}

.eventCardsHorizontal__Item:hover > .eventCardsHorizontal__Info{
    z-index: 10;
}

.eventCardsHorizontal__Item:hover .infoTitle{
    font-weight: bolder;
}

.eventCardsHorizontal__Item:hover::after{
    background: transparent linear-gradient(180deg, #16213E00 0%, #16213EA8 48%, #16213E 100%) 0% 0% no-repeat padding-box;
    right: 0;
    height: 100%;
    bottom: 0;
    content: "";
    width:100%;
    position: absolute;
    border-bottom: 5px solid var(--ion-color-warning);
    border-radius: 5px;
}

.eventCardsHorizontal__Info{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 1.5em;
    bottom: 0.5em;
    left: 1em;
    width: 570px;
}

.eventCardsHorizontal .infoTitle{
    width: 100%;
    font-size: 1.5em;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.eventCardsHorizontal .infoDisabled{
    color: var(--ion-color-medium-shade);
    font-size: 1em;
}

.carrousel__arrow {
    position: absolute;
    color: var(--ion-color-primary-contrast);
    cursor: pointer;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff88 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #1522414B;
    border: 0.5px solid #FFFFFF;
    border-radius: 8px;
    opacity: 1;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    width: 40px;
    height: 40px;
}

.carrousel__arrow ion-img {
    width: 20px;
    opacity: 1;
    margin: 0.25em 0 0 0 ;
    padding: 0;
}

.arrowLeft {
    left: -1em;
    transform: rotate(180deg);
}

.arrowRight {
    right: 0em;
}

.multi_entitlement_h {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 1.7em;
    background-color: #3264DC;
}

.multi_entitlement_h p {
    position: absolute;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FFFFFF;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    visibility: visible;
    padding: 0 5px 0 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 1200px) { 
    .eventCardsHorizontal__Item{
        width: 500px; 
        height: 300px;
    }

    .eventCardsHorizontal ion-img{
        width: 500px;
    }

    .eventCardsHorizontal .icoPlayInternal{
        bottom: 2.5em;
        left: 0;
    }

    .eventCardsHorizontal__Info{
        width: 490px;
        left: 0.25em;
        bottom: 0.25em;
        line-height: 1.15em;
    }

    .eventCardsHorizontal .infoTitle{
        white-space: nowrap;
        text-overflow: ellipsis;
        object-fit: fill;
        overflow: hidden;
        font-size: 16px;
    }

    .eventCardsHorizontal .infoDisabled{
        font-size: 14px;
    }

    .eventCardsHorizontal .tagLive{
        width: 6em;
        height: 2em;
        font-size: 0.75em;
    }    
}

@media (max-width: 991px){
    
    .eventCardsHorizontal{
        padding: 1em 0 0 0.5em;
    }

    .eventCardsHorizontal h3{
        font-size: 1em;
        line-height: 0;
        padding-left: 0.75em;
    }

    .eventCardsHorizontal ion-grid{
        max-width: 100vw;
    }

    .eventCardsHorizontal ion-row{
        width: 98vw;
    }

    .segment-tv-horizontal {
        width: 90%;
    }

    .eventCardsHorizontal__Item{
        width: 196px; 
        height: 149px;
    }

    .eventCardsHorizontal ion-img{
        width: 196px;
    }

    .eventCardsHorizontal .icoPlayInternal{
        bottom: 2.5em;
        left: 0;
    }

    .eventCardsHorizontal__Info{
        width: 190px;
        left: 0.25em;
        line-height: 1.15em;
    }

    .eventCardsHorizontal .infoTitle{
        width: 190px;
        font-size: 14px;
    }

    .eventCardsHorizontal .infoDisabled{
        font-size: 12px;
    }

    .eventCardsHorizontal .tagLive{
        width: 6em;
        height: 2em;
        font-size: 0.75em;
    }

    .multi_entitlement_h {
        height: 1.2em;
    }

    .multi_entitlement_h p {
        font-size: 10px;
    }
}